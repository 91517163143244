<template>
    <div class="app-search mb-5">
        <v-text-field
            v-if="!isEditing || readonly || disabled"
            key="displayField"
            class="app-search-item-display"
            :label="'Search ' + label"
            :hide-details="hideDetails"
            autocomplete="off"
            :readonly="readonly"
            :required="required"
            :dense="dense"
            :outlined="outlined"
            :disabled="disabled"
            @focus="edit" />

        <v-text-field
            v-if="isEditing && !readonly && !disabled"
            key="editField"
            v-model="searchText"
            autocomplete="off"
            autofocus
            :label="label"
            :hide-details="hideDetails"
            :readonly="readonly"
            :required="required"
            :dense="dense"
            :outlined="outlined"
            :disabled="disabled"
            @blur="clear"
            @keydown="onKeyDown" />

        <app-simple-table
            v-if="!noSelectedItems"
            :key="tableKey"
            :custom-filter="tableFilter"
            :table-schema="tableSchema"
            :hide-default-footer="true"
            :is-edit-disabled="true"
            :is-add-disabled="true">
            <template v-slot:cellButtons="{ item }">
                <v-icon dense @click="onRemove(item)">
                    mdi-close
                </v-icon>
            </template>
        </app-simple-table>

        <v-simple-table
            v-if="items && items.length && !readonly && !disabled"
            class="app-search-results"
            dense>
            <tbody>
                <tr
                    v-for="(item, index) in items"
                    :key="item.id"
                    :class="itemClass(index)"
                    @mousedown="onItemClick(item)">
                    <td>
                        {{ item.label }}
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </div>
</template>

<script>
import { search } from "@/services/searcher"
import { list } from "@/services/schemaApi";
import { or, equal } from "@/services/filtering"

export default {
    components: {
        appSimpleTable: () => import("@/components/AppSimpleTable")
    },
    props: {
        entityKey: {
            type: String,
            default: null,
            required: true
        },
        value: {
            type: Array,
            default: null
        },
        label: {
            type: String,
            default: () => "Search"
        },
        customFilter: {
            type: Object,
            default: null,
        },
        hideDetails: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: true,
        },
        outlined: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isEditing: false,
            searchText: null,
            results: null,
            selectedIndex: -1,
            selectedItems: [],
            tableKey: 0
        }
    },
    computed: {
        items() {
            return this.results?.items ?? [];
        },
        tableSchema() {
            return this.getTableSchema(this.entityKey);
        },
        tableFilter() {
            if (this.noSelectedItems) {
                return null;
            }
            return or(this.selectedItems.map(e => equal("Id", e.id, "guid")));
        },
        noSelectedItems() {
            return this.isEmpty(this.selectedItems) || this.selectedItems.length === 0;
        }
    },
    watch: {
        // Can debounce like this if you like.
        // searchText: debounce(function() { return this.search(); })
        async searchText() {
            await this.search();
        },
        selectedItems(selectedItems) {
            this.tableKey++;
            this.$emit("input", selectedItems.map(e => e.id));
        },
        value: {
            immediate: true,
            async handler(value) {
                if (!this.isEmpty(value) && value.length > 0) {
                    if (this.selectedItems.length == value.length
                        && this.selectedItems.every(e => value.includes(e.id))) {
                        return;
                    }
                    let model = { filter: or(value.map(e => equal("Id", e, "guid"))) };
                    let items = (await list(this.entityKey, model)).items;
                    this.selectedItems = items;
                }
                else if (this.selectedItems.length !== 0) {
                    this.selectedItems = [];
                }
            }
        }
    },
    methods: {
        edit() {
            this.isEditing = true;
            this.search();
        },
        async search() {
            // This component is used for small lookups, where we may not want to search at all.
            // if (isNullOrWhiteSpace(trim(this.searchText, "\""))) {
            //     this.results = null;
            //     return;
            // }

            let searchText = this.searchText;
            let results = await search(this.entityKey, searchText, this.customFilter);
            results.items = results
                .items
                .filter(e => !this.selectedItems.some(s => s.id === e.id));
            // Avoid showing previous search if results arrive out of order.
            if (searchText === this.searchText) {
                this.results = results;
            }
        },
        onKeyDown(e) {
            let handledKeys = ["ArrowDown", "ArrowUp", "Enter", "Tab"];

            if (handledKeys.includes(e.key)) {
                let handler = this[`on${e.key}`];
                handler(e);
            }
        },
        onArrowDown(e) {
            let index = this.selectedIndex + 1;
            if (index < this.items.length) {
                this.selectedIndex = index;
            }
            e.preventDefault();
        },
        onArrowUp(e) {
            let index = this.selectedIndex - 1;
            if (index > -1) {
                this.selectedIndex = index;
            }
            e.preventDefault();
        },
        onEnter(e) {
            if (this.selectedIndex > -1) {
                this.selectedItems.push(this.items[this.selectedIndex]);
                this.clear();
            }
            e.preventDefault();
        },
        onTab() {
            if (this.selectedIndex > -1) {
                this.selectedItems.push(this.items[this.selectedIndex]);
                this.clear();
            }
        },
        onItemClick(item) {
            this.selectedItems.push(item);
            this.clear();
        },
        onRemove(item) {
            this.selectedItems = this.selectedItems.filter(e => e.id !== item.id);
        },
        clear() {
            this.results = null;
            this.selectedIndex = -1;
            this.isEditing = false;
        },
        itemClass(index) {
            if (index == this.selectedIndex) {
                return "app-search-selected"
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "@/assets/style/theme.scss";

    .app-search {
        position: relative;
    }

    .app-search-results {
        position: absolute;
        z-index: 500;
        border: 1px solid rgba(0, 0, 0, 0.38);
        top: 40px;
        white-space: nowrap;
    }

    .app-search-selected {
        // I don't like using !important, but the vuetify selector for the hover is too specific and
        // likely to change.
        color: $color-primary;
        background-color: rgba($color-primary, 0.16) !important;
    }

    .app-search-results tr {
        cursor: pointer;
    }
</style>
